// ---------------
// The main App that contains all the necessary logic
// ---------------
import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom" // Route user to right view

// Styles
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components' // For styling all components, inside components
import { darken } from 'polished' // Adjust CSS properties programmatically
import { themes } from './themes' // Visual theme configurations
import {Helmet} from "react-helmet";

// Helpers
import axios from 'axios' // Promised based - Make XMLHttpRequests from the browser

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import moment from 'moment' // Times and dates
import 'moment/locale/fi';
import 'moment/locale/sv';
import i18n from './Translations' // Get the translated strings. All the translations uses are here in App.js

// Logging
import Log from './Log' // This helps us not firing log events in production

// Analytics
import ReactGA from 'react-ga' // React GA component

// Main components
import Footer from './Footer' // Loader spinner shown when loading data

// Main views
import Login from './Login' // Loader spinner shown when loading data
import PollList from './PollList'  // List polls as boxes
import Poll from './Poll' // Display a single poll
import EditPoll from './EditPoll' // Edit or create a new poll


// GlobalStyle is applied to all views
const GlobalStyle = createGlobalStyle`
  html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${props => darken(0.08, props.theme.background)};
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.fontFamily};
    letter-spacing: ${props => props.theme.letterSpacing};
    transition: background .1s linear, color .1s linear;
  }
`
const AppWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  transition: background .4s;
  background: ${props => props.theme.background};
`


class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentLang: i18n.language, // i18n autodetects browser language
      theme: themes.digita,
      pollUpdateIntervalInMS: 60000,
      token: '',
      username: '',
      password: '',
      showThemeOptions: false, // If logged in user is admin, then this is set to true
    }
  }

  componentDidMount() {
    //ReactGA.initialize('UA-88071868-2') // Initialize google analytics
    //this.sendGAPageview()
    this.setTheme()
    this.getToken() // Get the token from localstorage
  }

  clientHasLocalStorage() {
    const mod = 'localstoragetest'
    try {
        localStorage.setItem(mod, mod)
        localStorage.removeItem(mod)
        return true
    } catch(e) {
        return false
    }
  }


  sendGAPageview() {
    // This function is fired on login events (found token in local storage, or logging in),
    // and when login form is displayed.
    const username = this.getUsername()
    if (username) {
      Log.info('Google analytics: ' + username + ' pageview', 'App (sendGAPageview)')
      ReactGA.pageview('/' + username)
    }
    else {
      Log.info('No username found, but user was still marked as logged in', 'App Component')
      ReactGA.pageview('/undefined-username')
    }
  }

  handleThemeChange = (themeName: string) => {
    this.setState({theme: themes[themeName]})
  }

  setToken = (token) => {
    // or Seth Rogen?
    Log.info('Setting token in state', 'App (setToken)')
    this.setState({ token: token })
    if(this.clientHasLocalStorage()) {
      Log.info('Persisting token also in localStorage', 'App (setToken)')
      window.localStorage.setItem('token', token)
    } else {
      Log.info('No localstorage available, token only available in app state', 'App (setToken)')
    }
  }

  getToken = () => {
    // Or get Taken? This function has a very specific set of skills.
    // It gets the token or it gets the hose
    Log.info('Getting token..', 'App (getToken)')
    const currentToken = this.state.token
    const localToken = window.localStorage.getItem('token')
    if (currentToken) {
      Log.info('Token found in state', 'App (getToken)')
      //return currentToken
    }
    else if (localToken) {
      Log.info('Token not found in state, but found in local storage. Setting state and getting token.', 'App (getToken)')
      this.setState({ token: localToken })
      //return localToken
    }
    else {
      Log.warn('Token not found in state or local storage', 'App (getToken')
      //return false
    }
  }

  setUsername = (username) => {
    // or Seth Rogen?
    Log.info('Setting username in state', 'App (setUsername)')
    this.setState({ username: username })
    if(this.clientHasLocalStorage()) {
      Log.info('Persisting token also in localStorage', 'App (setUsername)')
      window.localStorage.setItem('username', username)
    } else {
      Log.info('No localstorage available, token only available in app state', 'App (setUsername)')
    }
  }

  getUsername = () => {
    // Get username from state or from localstorage
    const currentUsername = this.state.username
    const localUsername = window.localStorage.getItem('username')
    if (currentUsername) {
      return currentUsername
    }
    else if (localUsername) {
      this.setState({ username: localUsername })
      return localUsername
    }
    else {
      Log.warn('Username not found', 'App (getUsername)')
      return false
    }
  }

  setTheme = (username) => {
    // Match username to defined theme keys
    const user = username ? username : this.getUsername()
    for (const key in themes) {
      if (key === user) {
        this.setState({ theme: themes[user] })
        return
      } else {
        this.setState({ theme: themes.digita })
      }
    }
  }

  getUsername() {
    // Get username from state or from localstorage
    const currentUsername = this.state.username
    const localUsername = window.localStorage.getItem('username')
    if (currentUsername) {
      return currentUsername
    }
    else if (localUsername) {
      this.setState({ username: localUsername })
      return localUsername
    }
    else {
      Log.warn('Username not found', 'App (getUsername)')
      return false
    }
  }

  clearToken() {
    window.localStorage.removeItem('token')
    this.setState({ token: '' })
  }

  changeLanguage = (language) => {
    i18n.changeLanguage(language)
    this.setState({ currentLang: i18n.language})
    ReactGA.event({
      category: 'User',
      action: 'Changed language'
    })
  }

  refreshToken = () => {
    // Get JWT token from localStorage which is created on login
    const token = this.state.token

    if (token) {
      Log.warn('Error fetching data. Token was probably expired, trying to refresh token: '+ token)
      axios({
        method: 'post',
        url: `${window.baseURL}/auth/refresh/`,
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then((response) => {
            var data = response.data
            Log.info('Updating token: '+ data.token)
            this.setToken(data.token)
        })
        .catch((error) => {
            this.clearToken()
            Log.error('Refreshing errored: ' + error)
            this.props.history.push('/login')
        })
    } else {
      Log.error('Tried to refresh but no token found in local storage. Going to login view.')
      this.props.history.push('/login')
    }
  }


  render() {
    const { i18n } = this.props
    moment.locale(i18n.language) // Set moment localisation to current language

    // ThemeProvider provides the theme settings for all included components (that are exported 'withTheme')
    // GlobalStyle sets basic stuff for body and html
    // ModalContent is always present, but shown only when called
    return (
      <ThemeProvider theme={this.state.theme}>
        <Helmet>
          <link rel="stylesheet" href={this.state.theme.fontImport} />
        </Helmet>
        <Router>
          <AppWrapper>
            <GlobalStyle/>
              <Switch>
                <Route path="/" exact
                  key="pollList"
                  render={(props) =>
                    <PollList {...props}
                      token={this.state.token}
                      refreshToken={this.refreshToken}
                      setTheme={this.setTheme}
                      handleThemeChange={this.handleThemeChange}
                    />}
                />
                <Route
                  path='/login' exact
                  key="login"
                  render={(props) =>
                    <Login {...props}
                      setTheme={this.setTheme}
                      setToken={this.setToken}
                      token={this.state.token}
                      setUsername={this.setUsername}
                    />}
                />
                <Route
                  path="/poll/:id"
                  key="poll"
                  render={(props) =>
                    <Poll {...props}
                      setToken={this.setToken}
                      token={this.state.token}
                      setTheme={this.setTheme}
                      refreshToken={this.refreshToken}
                      setUsername={this.setUsername}
                    />}
                />
                <Route
                  path="/edit/:id/"
                  key="pollEdit"
                  render={(props) =>
                    <EditPoll {...props}
                      setToken={this.setToken}
                      token={this.state.token}
                      setTheme={this.setTheme}
                      refreshToken={this.refreshToken}
                      setUsername={this.setUsername}
                    />}
                />
                <Route
                  path="/duplicate/:id/"
                  key="pollEdit"
                  render={(props) =>
                    <EditPoll {...props}
                      setToken={this.setToken}
                      token={this.state.token}
                      isDuplicate={true}
                      setTheme={this.setTheme}
                      refreshToken={this.refreshToken}
                      setUsername={this.setUsername}
                    />}
                />
                <Route
                  path="/new" exact
                  key="newPoll"
                  render={(props) =>
                    <EditPoll {...props}
                      setToken={this.setToken}
                      setTheme={this.setTheme}
                      token={this.state.token}
                      refreshToken={this.refreshToken}
                    />}
                />
              </Switch>

          </AppWrapper>
          <Footer changeLanguage={this.changeLanguage} />
        </Router>
      </ThemeProvider>
    )
  }
}

export default withTranslation('translations')(App)
