// ---------------
// Each box that contains poll questions, answers and data in a compact format. Usually when listing polls.
// ---------------
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { lighten, darken, rgba } from 'polished'
import { channelThemes } from './ChannelThemes'

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import 'moment/locale/fi';
import 'moment/locale/sv';

import { layout } from './layoutConfig' // Layout configurations

const PollBoxContainer = styled.div`
  box-sizing: border-box;
  padding: 1.2rem;
  border-radius: 6px;
  border: 2px solid ${props => rgba(lighten(0.2, props.themeBg), 0.5)};
  transition: border .2s ease;
  text-align:left;
  position: relative;
  height: auto;
  background-image: linear-gradient(
    ${props => lighten(0.08, props.themeBg)},
    ${props => darken(0.06, props.themeBg)}
  );
  margin: .25rem 1em .25rem 1em;
  font-size: 0.8rem;
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 0.9rem;
  }
  &:hover {
    border: 2px solid ${props => rgba(lighten(0.6, props.themeBg), 0.8)};
  }
  &:active {
    text-decoration: none;
  }
  &.active {
    border-color: ${props => props.theme.accentColor};
  }
  &.closed {
    opacity: 0.8;
  }
`
const PollBoxData = styled.div`
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.textColor};
`

const PollBoxLogoContainer = styled.div`
  background: #fff;
  width: 32px;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 2px;
  height: 19px;
  padding: 2px;
  box-sizing: border-box;
  display: block;
  float: right;
  align-items: center;
  @media (min-width: ${layout.breakpoint.lg}) {
    width: 50px;
    height: 29px;
    padding: 6px;
    margin-left: 12px;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
     margin-left: 16px;
     position: relative;
     height: 40px;
     width: 72px;
     display: flex;
  }
`
const PollBoxLogo = styled.img`
  width: 100%;
  margin: auto;
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 98%
  }
`
const PollBoxTitle = styled.div`
  display: inline;
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: 200;
  margin-bottom: 1.2em;
  text-decoration: none;
  @media (min-width: ${layout.breakpoint.sm}) {
  }
  @media (min-width: ${layout.breakpoint.lg}) {
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`

const PollBoxQuestions = styled.div`
  position: relative;
`

const PollBoxQuestion = styled.div`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  margin-top: 1em;
  @media (min-width: ${layout.breakpoint.sm}) {
     font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.1em;
  }
  .bar {
    background-color: ${props => props.theme.accentColor}
  }
`
const VoteCount = styled.span`
  opacity: 0.5;
  margin-left: 0.35em;
`

const PollBarWrapper = styled.div`
  height: 4px;
  width: 100%;
  margin-top: 0.5rem;
  background: ${props => rgba(props.theme.textColor, 0.1)};
  border-radius: 3px;
`
const PollBar = styled.div`
  height: 4px;
  border-radius: 3px;
  width: ${props => props.barLength}%;
`


class PollBox extends Component {

  render() {
    const { t } = this.props
    const channelId = this.props.channelName
    const questions = this.props.pollQuestions
    const hasStartCount = (questions[0].start_count != null) ? true : false
    const hasEndCount = (questions[0].end_count != null) ? true : false
    let voteStartCountTotal = 0
    let voteEndCountTotal = 0

    if (hasStartCount) {
      for(let i = 0; i < questions.length; i++) { voteStartCountTotal += questions[i].start_count }
    }
    if (hasEndCount) {
      for(let i = 0; i < questions.length; i++) { voteEndCountTotal += questions[i].end_count }
    }

    let closedPollTotalVotes = voteEndCountTotal - voteStartCountTotal

    let channelLogo
      if (channelThemes.logos[channelId]) {
        channelLogo = channelThemes.logos[channelId]
      } else {
        channelLogo = ''
    }


    return (
      <PollBoxContainer className={this.props.pollStatus}  id={this.props.channelName} themeBg={this.props.theme.background}>
        <PollBoxLogoContainer>
          <PollBoxLogo src={ channelLogo } />
        </PollBoxLogoContainer>
        <PollBoxData>
          <PollBoxTitle>
            {this.props.title}
          </PollBoxTitle>
          <PollBoxQuestions>
          {questions.map(question => (
            <PollBoxQuestion key={question.id}>
              { this.props.pollStatus === 'closed' ?
                <div>
                  {question.value} <VoteCount>({question.end_count - question.start_count} {t('votes')})</VoteCount>
                  <PollBarWrapper>
                    <PollBar className="bar" barLength={closedPollTotalVotes === 0 ? 0 : ((question.end_count - question.start_count) / closedPollTotalVotes) * 100} />
                  </PollBarWrapper>
                </div>
              : <div>
                  {question.value}
                  <PollBarWrapper />
                </div>}
            </PollBoxQuestion>
          ))}
          </PollBoxQuestions>
        </PollBoxData>
      </PollBoxContainer>
    )
  }
}

export default withTranslation('translations')(withTheme(PollBox))
