// ---------------
// Main color themes and logos for channels
// ---------------
import yletv1Logo from './assets/yle-tv1.png'
import yletv2Logo from './assets/yle-tv2.png'
import yleteemafemLogo from './assets/yle-teemafem.png'
import tv5Logo from './assets/tv5.png'
import foxLogo from './assets/fox.png'
import subLogo from './assets/sub.png'
import livLogo from './assets/liv.png'
import kutonenLogo from './assets/kutonen.png'
import jimLogo from './assets/jim.png'
import nelonenLogo from './assets/nelonen.png'
import avaLogo from './assets/ava.png'
import friiLogo from './assets/frii.png'
import mtv3Logo from './assets/mtv3.png'
import alfatvLogo from './assets/alfatv.png'
import heroLogo from './assets/hero.png'
import estradiLogo from './assets/estradi.png'
import nationalgeographicLogo from './assets/natgeo.png'
import tlcLogo from './assets/tlc.png'
import viasatUrheiluLogo from './assets/viasat-urheilu.png'
import viasatUrheiluHDLogo from './assets/viasat-urheilu-hd.png'
import mtLogo from './assets/mt.png'
import inezLogo from './assets/inez.png'

export const channelThemes = {
  colors: {
    yletv1: '#6FA0FF',
    yletv2: '#B134F5',
    yleteemafem: '#BCD61D',
    tv5: '#B5B5B5',
    fox: '#D4A506',
    sub: '#EBD40F',
    liv: '#00E000',
    kutonen: '#87E3D8',
    jim: '#F7F263',
    nelonen: '#5570F5',
    ava: '#853D60',
    frii: '#C2485A',
    mtv3: '#E43112',
    alfatv: '#A42F12',
    hero: '#DF9387',
    popup: '#fff',
    estradi: '#CA005D',
    nationalgeographic: '#fbdc3a',
    tlc: '#ed697b',
    'tv-etusivu': '#fff',
    viasaturheilu: '#4848cd',
    viasaturheiluhd: '#74bf00',
    mt: '#9E9E9E',
    inez: '#ffe900',
  },
  logos: {
    yletv1: yletv1Logo,
    yletv2: yletv2Logo,
    yleteemafem: yleteemafemLogo,
    tv5: tv5Logo,
    fox: foxLogo,
    sub: subLogo,
    liv: livLogo,
    kutonen: kutonenLogo,
    jim: jimLogo,
    nelonen: nelonenLogo,
    ava: avaLogo,
    frii: friiLogo,
    mtv3: mtv3Logo,
    alfatv: alfatvLogo,
    hero: heroLogo,
    estradi: estradiLogo,
    nationalgeographic: nationalgeographicLogo,
    tlc: tlcLogo,
    viasaturheilu: viasatUrheiluLogo,
    viasaturheiluhd: viasatUrheiluHDLogo,
    mt: mtLogo,
    inez: inezLogo,
  },
  logoBackground: {
    yletv1: 'rgba(255,255,255,1)',
    yletv2: 'rgba(255,255,255,1)',
    yleteemafem: 'rgba(255,255,255,1)',
    tv5: 'rgba(255,255,255,1)',
    fox: 'rgba(255,255,255,1)',
    sub: 'rgba(255,255,255,1)',
    liv: 'rgba(255,255,255,1)',
    kutonen: 'rgba(255,255,255,1)',
    jim: 'rgba(255,255,255,1)',
    nelonen: 'rgba(255,255,255,1)',
    ava: 'rgba(255,255,255,1)',
    frii: 'rgba(255,255,255,1)',
    mtv3: 'rgba(255,255,255,1)',
    alfatv: 'rgba(255,255,255,1)',
    hero: 'rgba(255,255,255,1)',
    estradi: 'rgba(255,255,255,1)',
    nationalgeographic: 'rgba(255,255,255,1)',
    tlc: 'rgba(255,255,255,1)',
    viasaturheilu: 'rgba(255,255,255,1)',
    viasaturheiluhd: 'rgba(255,255,255,1)',
    mt: 'rgba(255,255,255,1)',
    inez: 'rgba(255,255,255,0)',
  }
};
