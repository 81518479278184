import React from 'react';
import Modal from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import styled from 'styled-components'

const ModalContent = styled.div`
  color: #101010;
`

const PopUp = (props) => (
  <Modal styles={ {modal: {borderRadius: '10px', padding: '1em 1.5em'}} } open={props.infoModalOpen} onClose={props.closeInfoModal} center>
    <ModalContent>
      <h1>{props.title}</h1>
      <p>{props.p1}</p>
      <p>{props.p2}</p>
      <p>{props.p3}</p>
    </ModalContent>
  </Modal>
)

export default PopUp;
