// ---------------
// Footer
// ---------------
import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom" // Route user to right view
import styled, { withTheme } from 'styled-components'
import { lighten, darken } from 'polished'

// Themes are the account specific themes for the UI
import { layout } from './layoutConfig' // Layout configurations

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import moment from 'moment' // Times and dates

// Helpers
import axios from 'axios' // Promised based - Make XMLHttpRequests from the browser

// Logging
import Log from './Log' // This helps us not firing log events in production

const LoginBoxContainer = styled.div`
  display: flex;
  min-height: 85vh;
  align-items: center;
`
const LoginBox = styled.form`
  display: block;
  width: 75%;
  min-width: 280px;
  max-width: 500px;
  background-image: linear-gradient(
    ${props => lighten(0.08, props.theme.background)},
    ${props => darken(0.06, props.theme.background)}
  );
  border-radius: 6px;
  padding: 2rem 2rem 2rem;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(0,0,0,.3);
  transition: background-image .3s linear;
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 70%;
  }
`
const LoginLogo = styled.div`
  width: 100%;
  height: 56px;
  margin: 1rem auto 1rem;
  display: flex;
  align-items: center;
  text-align: center;
`
const LoginLogoImage = styled.img`
  height: 100%;
  margin: 0 auto;
`
const LoginBoxTitle = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 0;
`
const TextField = styled.input`
  padding: 1rem;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 6px 40px rgba(0,0,0,.1);
  text-align: center;
  border: 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: 1.2rem;
  margin: 0.25rem 0;
  box-sizing: border-box;
`
const LoginButton = styled.button`
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  border: 0;
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamily};
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 6px;

  background-color: ${props => darken(0.06, props.theme.accentColor)};
  transition: background-color .3s ease;
  &:hover,
  &:active {
    background-color: ${props => darken(0.1, props.theme.accentColor)}
  }
`
const LoginErrorMessage = styled.p`
  display: block;
`
const DataErrorMessage = styled.p`
  background: rgba(255,40,40,.1);
  padding: 0.5rem;
  border-radius: 3px;
  display: block;
`

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showLoginErrorMessage: false,
      showDataErrorMessage: false,
      redirectToReferrer: false,
      username: '',
      password: '',
    }
  }

  handleLoginSubmit = (event) => {
    const username = this.state.username,
          password = this.state.password,
          payload = {username: username, password: password}
    Log.info("Sending login data and getting JWT token...")
    axios.post(`${window.baseURL}/auth/`, payload)
      .then((response) => {
          const data = response.data
          this.props.setToken(data.token)
          this.props.setUsername(username)
          this.setState({
            password: '',
            redirectToReferrer: true,
          }) // Clear password from state, redirect to referrer
          this.props.setTheme(username)
          //this.sendGAPageview()
      })
      .catch((error) => {
         Log.error('Error happened in login.', 'App (handleLoginSubmit)')
         Log.error(error, 'App (handleLoginSubmit)')
         this.props.history.push('/login')
      });
    event.preventDefault()
    return false
  }

  handleLoginUsername = (event) => {
    this.setState({username: event.target.value})
  }

  handleLoginPassword = (event) => {
    this.setState({password: event.target.value})
  }

  setTheme = (event) => {
    this.props.setTheme(event.target.value)
  }


  render() {
    const { t, i18n } = this.props
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    moment.locale(i18n.language) // Set moment localisation to current language

    const showLoginError = this.state.showLoginErrorMessage
    const showDataError = this.state.showDataErrorMessage

    let loginError = ''
    let dataError = ''

    if(showLoginError) {
      loginError = (
        <LoginErrorMessage>
          {t('Wrong username or password')}
        </LoginErrorMessage>
      )
    }

    if (showDataError) {
      dataError = (
        <DataErrorMessage>
          {t('Unable to get data from server.')}
        </DataErrorMessage>
      )
    }

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div>
        <LoginBoxContainer>
          <LoginBox onSubmit={this.handleLoginSubmit}>
            <LoginLogo>
              <LoginLogoImage src={this.props.theme.logo} />
            </LoginLogo>
            <LoginBoxTitle>{t('Suomi katsoo')}</LoginBoxTitle>
            <TextField
              hintText={t('Enter your username')}
              value={this.state.username}
              onChange={this.handleLoginUsername}
              onBlur={this.setTheme}
            />
            <TextField
              type="password"
              value={this.state.password}
              hintText={t('Enter your password')}
              onChange={this.handleLoginPassword}
            />
            <LoginButton type="Submit">
              {t('Submit')}
            </LoginButton>
            { loginError }
            { dataError }
          </LoginBox>
        </LoginBoxContainer>
      </div>
    )
  }
}

export default withTranslation('translations')(withRouter(withTheme(Login)))
