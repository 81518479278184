// ---------------
// The header which is shown on the main page, after login
// ---------------
import React, { Component } from 'react'
import { Link } from "react-router-dom" // Route user to right view
import styled, { withTheme } from 'styled-components'
import { darken, rgba } from 'polished' // Style effects
import { withTranslation } from 'react-i18next' // Translate strings

import UserSettings from './UserSettings'
import { layout } from './layoutConfig' // Layout configurations


const AppHeaderWrapper = styled.div`
  padding: 4px 8px 4px;
  border-bottom: 1px solid ${props => rgba(props.borderColor, 0.1)};
  border-top: 3px solid ${props => darken(0.08, props.indicatorBg)};
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 0 1.5rem;
    border-top: 5px solid ${props => darken(0.08, props.indicatorBg)};
  }
`
const AppHeaderContainer = styled.div`
  height: 34px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${layout.breakpoint.lg}) {
    height: 68px;
  }
`
const ButtonLink = styled(Link)`
  background: ${props => rgba(props.theme.borderColor, 0.2)};
  color: ${props => props.theme.borderColor};
  border-radius: 6px;
  float: right;
  padding: 0.6rem 1rem;
  text-decoration: none;
`
const AppLogoContainer = styled.div`
  width: 25%;
  text-align: left;
  margin-top: 4px;
`
const AppLogo = styled.img`
  max-height: 20px;
  @media (min-width: ${layout.breakpoint.lg}) {
    max-height: 34px;
  }
`
const UserSettingsContainer = styled.div`
  width: 25%;
  @media (min-width: ${layout.breakpoint.lg}) {
    position: relative;
  }
`

class AppHeader extends Component {

  render() {

    const { t } = this.props

    return (
      <AppHeaderWrapper indicatorBg={this.props.theme.background} borderColor={this.props.theme.borderColor}>
        <AppHeaderContainer>
          <AppLogoContainer>
            <AppLogo src={this.props.theme.logo} alt="logo" />
          </AppLogoContainer>

          <ButtonLink to='/new'>{t('Create a new poll')}</ButtonLink>

          <UserSettingsContainer>
            <UserSettings
              logoutString={this.props.logoutString}
              accountName={this.props.userName}
              handleThemeChange={this.props.handleThemeChange}
              handleLogout={this.props.handleLogout}
              showThemeOptions={this.props.showThemeOptions}
              themeList={this.props.themeList}
              themesString={this.props.themesString}
            />
          </UserSettingsContainer>
        </AppHeaderContainer>
      </AppHeaderWrapper>
    )
  }
}

export default withTranslation('translations')(withTheme(AppHeader))
