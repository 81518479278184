// ---------------
// Each box that contains channel data, like viewers, program, percent and channel logo.
// ---------------
import React, { Component } from 'react'
import { Redirect, Link } from "react-router-dom" // Route user to right view
import styled, { keyframes, withTheme } from 'styled-components'
import { lighten, darken, rgba } from 'polished'
import { channelThemes } from './ChannelThemes'
import posed, {PoseGroup} from 'react-pose'
import PopUp from './PopUp'

// Themes are the account specific themes for the UI
import { layout } from './layoutConfig' // Layout configurations

// Helpers
import axios from 'axios' // Promised based - Make XMLHttpRequests from the browser

// Logging
import Log from './Log' // This helps us not firing log events in production

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import moment from 'moment' // Times and dates

// Components
import Spinner from './Spinner' // Wait spinner
import Countdown from 'react-countdown' // Countdown component



const AnimatedPollContents = posed.div({
  enter: { y: 0, opacity: 1, delayChildren: 300 },
  exit: { y: 24, opacity: 0 }
})

const AnimatedSpinner = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})

const PollBoxContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 2rem;
  border-radius: 12px;
  flex-direction: column;
  transition: background-image .4s;
  text-align:left;
  position: relative;
  height: auto;
  background-image: linear-gradient(
    ${props => lighten(0.08, props.themeBg)},
    ${props => darken(0.06, props.themeBg)}
  );
  max-width: 980px;
  min-width: 80%;
  margin: 0 auto;
  font-size: 1rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    flex-direction: row;
    padding: 4rem;
    max-width: 980px;
    min-width: auto;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.1rem;
    padding: 4rem;
  }
  &:active {
    text-decoration: none;
  }
`
const PollBoxContent = styled.div`
  padding: 0;
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 0 2rem 0 0;
    min-width: 480px;
    width: 60%;
  }
`
const PollBoxControlsBottom = styled.div`
  align-self: flex-end;
  width: 100%;
  padding-top: 1em;
  border-top: 1px solid rgba(255,255,255,.1)

`
const PollBoxControls = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  border-top: 1px solid  ${props => rgba(props.theme.textColor, 0.2)};
  padding: 2rem 0 0 0;
  margin-top: 2rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    border-left: 1px solid  ${props => rgba(props.theme.textColor, 0.2)};
    border-top: 0;
    padding: 0 0 0 2rem;
    width: 40%;
    margin-top: 0rem;
    min-width: 240px;
  }
`
const fillButton = keyframes`
  to {
    width: 100%;
    opacity: 1;
  }
`
const ActivationEndDateAnimated = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 }
})
const ActivationEndDate = styled(ActivationEndDateAnimated)`
  font-size: 1.3em;
  text-align: center;
`
const CountdownAnimated = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 16, opacity: 0 }
})


const CountdownHolder = styled(CountdownAnimated)`
  font-size: 1.6em;
  text-align: center;
`
const CountdownText = styled(Countdown)`
  border-top: 10px solid red;
  span {
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  }
`
const WaitingTextAnimated = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 }
})
const WaitingText= styled(WaitingTextAnimated)`
  font-size: 1.6em;
  text-align: center;
`
const ActivationButtonHolder = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 0 }
})
const ActivationButton = styled.div`
  font-size: 1.1em;
  user-select: none;
  border: 0;
  border-radius: 6px;
  border: 3px solid ${props => props.theme.accentColor};
  padding: 1rem 1rem 1.1rem;
  color: ${props => props.theme.accentColor};
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(0,0,0,.4);
  box-sizing: border-box;
  position: relative;
  transition: color .3s, border-color .3s;
  &:hover {
    color: ${props => lighten(0.3, props.theme.accentColor)}
    border-color: ${props => lighten(0.3, props.theme.accentColor)}
  }
  .buttonFillAnimation {
    width: 0%;
    height: 100%;
    display: block;
    opacity: 0.2;
    bottom: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    transition: opacity .3s;
    background: ${props => rgba(props.theme.accentColor, 0.9)};
    background-blend-mode: multiply;
  }
  &.animateFill .buttonFillAnimation {
    animation: ${fillButton} 2s forwards;
  }
`
const HelperText = styled.div`
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
  text-align: center;
  padding: 0.8em 0;
`
const PollBoxInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
`
const PollBoxInfo = styled.div`
  order: 1;
  width: 100%;
  & div {
    margin-bottom: 0.5em;
    opacity: .8;
  }
`
const PollBoxLogoContainer = styled.div`
  background: ${props => props.backgroundColor};
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  order: 2;
  align-items: center;
  margin-left: 16px;
  position: relative;
  height: 40px;
  width: 72px;
  display: flex;
`
const PollBoxLogo = styled.img`
  width: 100%
  margin: 0 auto;
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 98%
  }
`
const PollBoxHeader = styled.div`
  display: flex;
`
const PollBoxTitle = styled.div`
  display: inline;
  font-size: 1.6em;
  width: 100%;
  font-weight: 200;
  margin-bottom: 1em;
  text-decoration: none;
  @media (min-width: ${layout.breakpoint.sm}) {
  }
  @media (min-width: ${layout.breakpoint.lg}) {
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`
const PollBoxQuestions = styled.div`
  position: relative;
`
const Wrapper = styled(AnimatedPollContents)`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  justify-content: center;
      align-items: center;
  min-height: 100vh;
`
const PollBoxQuestion = styled.div`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  margin-top: 1.2em;
  @media (min-width: ${layout.breakpoint.sm}) {
     font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.1em;
  }
  .bar {
    background-color: ${props => props.theme.accentColor}
  }
`
const VoteCount = styled.span`
  opacity: 0.5;
  margin-left: 0.35em;
`

const PollBarWrapper = styled.div`
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 0.7rem;
  background:  ${props => rgba(props.theme.textColor, 0.1)};
  border-radius: 3px;
`
const PollBar = styled.div`
  height: 4px;
  position: absolute;
  left: -100%;
  border-radius: 3px;
  transition: transform .3s ease-in-out;
  transform: translateX(${props => props.barLength}%);
  width: 100%;
`
const BackLink = styled(Link)`
  color:  ${props => rgba(props.theme.textColor, 0.8)};
  text-decoration: none;
  padding: 1rem 1.5rem 1.05rem;
  margin: 1rem;
  border-radius: 6px;
  background: ${props => rgba(props.theme.textColor, 0.2)}
`
const PollBoxButtons = styled.div`
  display: flex;

`

class Poll extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
      pollStatus: 'waiting', // can be 'waiting', 'activatable', 'closed' and 'active'
      poll: '',
      authFailed: false,
      liveVoteData: [], // this is where we store live vote data
      longpressActive: false,
      activePollDataPollingTimeInMS: 3000,
      infoModalOpen: false
    }
  }

  componentDidMount() {
    //ReactGA.initialize('UA-88071868-2') // Initialize google analytics
    //this.sendGAPageview()
    this.getPollData()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  fetchPollVotes() {
    const { id } = this.props.match.params
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url: `${window.baseURL}/poll/${id}/vote_status`
      })
      .then(res => {
        if (res.data) {
          // Check that there is data so we don't overwrite votes with empty responses
          const data = res.data
          const questions = this.state.poll.items
          const liveVoteData = data[0].choices
          let calculatedVotes = []

          // This would be the pre calculation of votes - live vote values should be always as big or larger than start values
          // ...if data points are acting correctly
          for(let i = 0; i < questions.length; i++) {
            const voteValue = liveVoteData.filter(x => x.id === questions[i].pos).map(x => x.votes)
            const startCount = questions[i].start_count
            var obj = {}
            obj['id'] = questions[i].pos
            obj['votes'] = (voteValue >= startCount) ? (voteValue - startCount) : 0
            calculatedVotes.push(obj)
          }
          this.setState({liveVoteData: calculatedVotes})
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.props.refreshToken()
          } else {
            Log.error('Response error', 'Poll (fetchPollVotes)')
            this.openInfoModal()
          }
        } else if (error.request) {
          Log.error('Request error', 'Poll (fetchPollVotes)')
          Log.error(error.request)
          this.openInfoModal()
        } else {
          Log.error('Other error', 'Poll (fetchPollVotes)')
          Log.error(error.message)
          this.openInfoModal()
        }
      })
    } else {
      Log.error('No token found, opening log in view', 'Poll (fetchPollVotes)')
      this.openInfoModal()
    }
  }

  startGetPollVotesTimer() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(
      () => {
      this.getPollData()
      }, this.state.activePollDataPollingTimeInMS
    )
  }

  sendPollActivation() {
    const { id } = this.props.match.params
    const token = this.props.token
    if (token) {
      axios({
        method:'post',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/poll/${id}/`
      })
      //axios.get(`/2.json`)
       .then(res => {
          const response = res.data
          console.log('Poll activation response '+ response, 'Poll (sendPollActivation)')
          this.setState(
            {pollStatus: 'active'},
            () => this.getPollData()
          )
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else if (error.request) {
              Log.error('Request error', 'Poll (sendPollActivation)')
              Log.error(error.request)
              this.openInfoModal()
            } else {
              Log.error('Other error', 'Poll (sendPollActivation)')
              Log.error(error.message)
              this.openInfoModal()
            }
          }
        })
      } else {
        Log.error('No token found, opening log in view', 'Poll (sendPollActivation)')
        this.openInfoModal()
      }
  }

  getPollData() {
    const { id } = this.props.match.params
    const token = this.props.token
    if (id === undefined) {
      // If id is not available, throw user back to frontpage where we list all polls
      this.props.history.push('/')
    } else {
      if (token) {
        axios({
          method:'get',
          headers: { Authorization: 'Bearer ' + token },
          url:`${window.baseURL}/poll/${id}/`
        })
        //axios.get(`/2.json`)
        .then(res => {
            const poll = res.data
            const now = Date.now()
            const channelName = this.normalizeChannelname(poll.channel)
            const pollStatus = moment(poll.activation_end).isAfter(now) ? 'active' : poll.items[0].end_count ? 'closed' : 'activatable'
            this.setState({
              dataLoaded: true,
              poll: poll,
              authFailed: false,
              pollStatus: pollStatus,
            })

            console.log('setting theme as ' + poll.channel)
            this.props.setTheme(channelName)


            // If poll is active, start the timeout to refetch the poll data
            if (pollStatus === 'active') {
              this.fetchPollVotes()
              this.startGetPollVotesTimer()
            } else {
              clearTimeout(this.timeout)
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.props.refreshToken()
              } else if (error.request) {
                Log.error('Request error')
                Log.error(error.request)
                this.openInfoModal()
              } else {
                Log.error('Other error')
                Log.error(error.message)
                this.openInfoModal()
              }
            }
          })
        } else {
          Log.error('No token found, opening log in view')
          this.openInfoModal()
        }
      }
  }


  getNetworkInformation() {
    // Get JWT token from localStorage which is created on login
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/channels/`
      })
        .then(res => {
          // We get the channel names from data that belong to this username's network
          const data = res.data
          this.setState({ networkChannels: data.channels })
          if (data.is_admin === true) {
            this.setState({ showThemeOptions: true })
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else {
              Log.error('Response error', 'Poll (getNetworkInformation)')
              this.openInfoModal()
            }
          } else if (error.request) {
            Log.error('Request error', 'Poll (getNetworkInformation)')
            Log.error(error.request)
            this.openInfoModal()
          } else {
            Log.error('Other error', 'Poll (getNetworkInformation)')
            Log.error(error.message)
            this.openInfoModal()
          }
        })
    } else {
      Log.error('No token found, opening log in view', 'Poll (getNetworkInformation)')
      this.openInfoModal()
    }
  }

  getChannelLogo = (channelId) => {
    if (channelThemes.logos[channelId]) {
      return channelThemes.logos[channelId]
    } else {
      return ''
    }
  }

  getChannelLogoBackground = (channelId) => {
    if (channelThemes.logoBackground[channelId]) {
      return channelThemes.logoBackground[channelId]
    } else {
      return ''
    }
  }

  normalizeChannelname = (channelName) => {
    return channelName.replace(/ /g,'').replace(/&/,'').replace(/\(|\)/g,'').replace(/\./g, '').toLowerCase()
  }

  activatePoll = () => {
    this.setState({
      longpressActive: false,
      pollStatus: 'waiting',
    })
    this.sendPollActivation()
  }

  handleButtonPress = (event) => {
    var buttons = event.buttons;
    if (buttons === 2) {
      // Right click, do nothing
    } else {
      this.setState({ longpressActive: true })
      this.buttonPressTimer = setTimeout(() => this.activatePoll(), 2000)
    }
  }

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer)
    this.setState({
      longpressActive: false
    })
  }

  openInfoModal = () => {
    this.setState({ infoModalOpen: true })
  }

  closeInfoModal = () => {
    this.setState({ infoModalOpen: false, authFailed: true })
  }

  render() {
    const { t } = this.props
    const { id } = this.props.match.params
    const editUrl = '/edit/' + id
    const duplicateUrl = '/duplicate/' + id
    const { poll, dataLoaded, authFailed, pollStatus, liveVoteData } = this.state
    let content

    if (dataLoaded) {
      const questions = poll.items
      const timeoutInMS= poll.timeout * 1000;
      const activationEndInMS = poll.activation_end != null ? moment.utc(poll.activation_end) : null
      const pollDuration = moment.utc(timeoutInMS).format('mm:ss');
      const activationEnd = poll.activation_end != null ? moment(poll.activation_end).format('L HH:hh').toString() : null
      const activationEndFromNow = poll.activation_end != null ? moment(poll.activation_end).fromNow().toString() : null

      let voteStartCountTotal = 0
      let voteEndCountTotal = 0
      let voteLiveCountTotal = 0

      if (pollStatus === 'closed') {
        for(let i = 0; i < questions.length; i++) { voteStartCountTotal += questions[i].start_count }
        for(let i = 0; i < questions.length; i++) { voteEndCountTotal += questions[i].end_count }
      }
      if (pollStatus === 'active') {
        for(let i = 0; i < questions.length; i++) { voteLiveCountTotal += (liveVoteData && liveVoteData[i] ? liveVoteData[i].votes : null) }
      }

      let closedPollTotalVotes = voteEndCountTotal - voteStartCountTotal
      let activePollTotalVotes = voteLiveCountTotal

      content = (
        <Wrapper key="pollListWrapper">
          <PollBoxContainer  id={this.props.channelName} themeBg={this.props.theme.background}>
            <PollBoxContent>
              <PollBoxHeader>
                <PollBoxLogoContainer backgroundColor={ this.getChannelLogoBackground(this.normalizeChannelname(poll.channel))}>
                    <PollBoxLogo src={ this.getChannelLogo(this.normalizeChannelname(poll.channel))} />
                </PollBoxLogoContainer>
                <PollBoxTitle>
                  {poll.title}
                </PollBoxTitle>
              </PollBoxHeader>

              <PollBoxQuestions>
              {questions.map(question => (
                <div key={question.id}>

                  {pollStatus === 'activatable'   ?
                    <PollBoxQuestion key={question.pos}>
                      {question.value}
                      <PollBarWrapper />
                    </PollBoxQuestion>
                  : ''}

                  {pollStatus === 'active' || pollStatus === 'waiting' ?
                    <PollBoxQuestion key={question.pos}>
                      {question.value}
                      <VoteCount>({liveVoteData.filter(x => x.id === question.pos).map(x => x.votes)} votes)</VoteCount>
                      <PollBarWrapper>
                        <PollBar className="bar" barLength={Math.round(((liveVoteData.filter(x => x.id === question.pos).map(x => x.votes)) / activePollTotalVotes) * 100)} />
                      </PollBarWrapper>
                    </PollBoxQuestion>
                  : ''}

                  {pollStatus === 'closed' ?
                    <PollBoxQuestion key={question.pos}>
                      {question.value}
                      <VoteCount>({question.end_count - question.start_count} votes)</VoteCount>
                      <PollBarWrapper>
                        <PollBar className="bar" barLength={Math.round(((question.end_count - question.start_count) / closedPollTotalVotes) * 100)} />
                      </PollBarWrapper>
                    </PollBoxQuestion>
                  : ''}
                </div>
              ))}
              </PollBoxQuestions>
            </PollBoxContent>

            <PollBoxControls>
              <PollBoxInfoWrapper>
                <PollBoxInfo>
                  <div>{t('Duration')}: {pollDuration}</div>
                  <div>{poll.publish_type === 'show-results' ? t('Show results to viewers') : t('Hide results from viewers')}</div>
                </PollBoxInfo>
              </PollBoxInfoWrapper>
              <PollBoxControlsBottom>
                <PoseGroup>

                  {pollStatus === 'waiting' ?
                    <WaitingText key='waitingText'>
                      <p>Waiting...</p>
                    </WaitingText>
                  : ''}

                  {pollStatus === 'activatable' ?
                    <ActivationButtonHolder key='activationButton' >
                      <ActivationButton
                        onTouchStart={this.handleButtonPress}
                        onTouchEnd={this.handleButtonRelease}
                        onMouseDown={this.handleButtonPress}
                        onMouseUp={this.handleButtonRelease}
                        onMouseLeave={this.handleButtonRelease}
                        disabled={pollStatus !== 'active'}
                        className={
                          this.state.longpressActive ? 'animateFill' : ''
                        }>
                          {t('Activate poll')}
                          <div className="buttonFillAnimation" />
                      </ActivationButton>
                      <HelperText>{t('Long press to activate')}</HelperText>
                    </ActivationButtonHolder>
                  : ''}

                  {pollStatus === 'active' ?
                    <CountdownHolder key='countdown'>
                      <CountdownText date={activationEndInMS} />
                      <HelperText>{t('Until poll closes')}</HelperText>
                    </CountdownHolder>
                  : ''}

                  {pollStatus === 'closed' ?
                    <ActivationEndDate key="enddate">
                      <HelperText>{t('Poll closed')}</HelperText>
                      {activationEnd}
                      <HelperText>{activationEndFromNow}</HelperText>
                    </ActivationEndDate>
                  : ''}
                </PoseGroup>
              </PollBoxControlsBottom>
            </PollBoxControls>
          </PollBoxContainer>
          <PollBoxButtons>
            <BackLink to='/'>{t('Back to all polls')}</BackLink>
            <BackLink to={duplicateUrl}>{t('Duplicate this poll')}</BackLink>
            {pollStatus === 'activatable' ? <BackLink to={editUrl}>{t('Edit this poll')}</BackLink> : '' }
          </PollBoxButtons>
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </Wrapper>
      )
    }

    // If we're not yet gotten the data loaded (due to slow network for example)
    else {
      content = (
        <AnimatedSpinner key="spinner">
          <Spinner loaderStatusMessage={t('Getting data')} />
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </AnimatedSpinner>
      )
    }

    // If authentication fails when fetching data, throw user back to login
    if (authFailed === true) {
      return <Redirect to='/login' />
    }

    // Render the content
    return (
      <div>
        {content}
      </div>
    )
  }
}

export default withTranslation('translations')(withTheme(Poll))
