// ---------------
// Footer
// ---------------
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { darken, rgba } from 'polished'

import PopUp from './PopUp';

// Themes are the account specific themes for the UI
import { themes } from './themes' // Visual theme configurations
import { layout } from './layoutConfig' // Layout configurations

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings


const FooterWrapper = styled.div`
  background: ${props => darken(0.08, props.theme.background)};
  padding-bottom: 1em;
  text-align: center;
`
const FooterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${props => rgba(props.theme.borderColor, 0.1)};
`
const FooterLogoWrapper = styled.div `
  width: 20%;
  margin: 1em;
  max-width: 100px;
`
const FooterLogo = styled.img`
  width: 100%;
`
const LanguageSwitch = styled.a`
  margin: 1rem;
  display: inline-block;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: ${props => props.currentLang ? '1' : '.5'};
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1rem
  }
`
const InfoLink = styled.a`
  cursor: pointer;
  margin: 1rem;
  display: inline-block;
  font-size: 0.8rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1rem
  }
`

class Footer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      infoModalOpen: false,
    }
  }

  openInfoModal = () => {
    this.setState({ infoModalOpen: true })
    /*ReactGA.event({
      category: 'User',
      action: 'Viewed info modal'
    })*/
  }

  closeInfoModal = () => {
    this.setState({ infoModalOpen: false })
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <FooterWrapper>
          <LanguageSwitch currentLang={ (this.props.currentLang === 'fi-FI') ? true : false } onClick={() => this.props.changeLanguage('fi-FI')}>FI</LanguageSwitch>
          <LanguageSwitch currentLang={ (this.props.currentLang === 'en-GB') ? true : false } onClick={() => this.props.changeLanguage('en-GB')}>EN</LanguageSwitch>
          <FooterFooter>
            <FooterLogoWrapper>
              <FooterLogo src={themes.digita.footerLogo} alt="logo" />
            </FooterLogoWrapper>
            <InfoLink onClick={this.openInfoModal}>{t('About this service')}</InfoLink>
          </FooterFooter>
        </FooterWrapper>
        <PopUp title={t('InfoModalHeader')} p1={t('InfoModalP1')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
      </div>
    )
  }
}

export default withTranslation('translations')(withTheme(Footer))
