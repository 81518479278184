import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: {
          "Suomi katsoo": "Ask-a-viewer",
          "Wrong username or password": "Wrong username or password",
          "Enter your username": "Enter your username",
          "Enter your password": "Enter your password",
          "Submit": "Submit",
          "Getting data": "Getting data",
          "Log out": "Log out",
          "Unable to get data from server": "Connection to the server was disconnected",
          "Themes": "Themes",
          "The service gathers anonymous usage statistics with Google Analytics": "The service gathers anonymous usage statistics with Google Analytics",
          "About this service": "About this service",
          "Duration": "Poll duration",
          "Activate poll": "Activate poll",
          "Long press to activate": "Long press to activate",
          "votes": "votes",
          "Show results to viewers": "Show results to viewers",
          "Hide results from viewers": "Hide results from viewers",
          "InfoModalHeader": "About this service",
          "InfoModalP1": "Digita Ask-a-viewer makes it possible to publish interactive polls inside Hybrid-TV's",
          "InfoModalP2": "",
          "InfoModalP3": "",
          "Enter poll title": "Enter poll title",
          "Enter question": "Enter question",
          "Choose a channel": "Choose the channel where poll is shown",
          "No channels": "You don't have any channel to use",
          "second": "second",
          "second_plural": "seconds",
          "minute": "minute",
          "minute_plural": "minutes",
          "hour": "hour",
          "hour_plural": "hours",
          "Show poll results to TV viewers": "Show poll results to TV viewers",
          "Don't show poll results to TV viewers": "Don't show poll results to TV viewers",
          "Save new poll": "Save poll",
          "Long press to create the poll": "Long press to create the poll",
          "Not a valid poll": "Fill the title, at least two first question and select a channel",
          "Cancel": "Cancel",
          "Channel where the poll is shown in": "Channel where the poll is shown in",
          "How long the poll is visible": "How long the poll is visible",
          "Back to all polls": "Back to all polls",
          "Edit this poll": "Edit this poll",
          "Poll closed": "Poll closed",
          "Create a new poll": "Create a new poll",
          "Duplicate this poll": "Duplicate this poll",
          "Something went wrong": "Something went wrong",
          "Try again later": "Try again later or contact support"
        }
      },
      fi: {
        translations: {
          "Suomi katsoo": "Kysy katsojalta",
          "Wrong username or password": "Väärä käyttäjänimi tai salasana",
          "Enter your username": "Syötä käyttäjänimi",
          "Enter your password": "Syötä salasana",
          "Submit": "Lähetä",
          "Getting data": "Haetaan tietoja",
          "Log out": "Kirjaudu ulos",
          "Unable to get data from server": "Yhteyttä palvelimeen ei saatu.",
          "Themes": "Teemat",
          "The service gathers anonymous usage statistics with Google Analytics": "Palvelu seuraa käyttöä nimettömästi Google Analytics -evästeillä",
          "About this service": "Tietoa palvelusta",
          "Duration": "Kyselyn kesto",
          "Activate poll": "Aktivoi kysely",
          "Long press to activate": "Aktivoi pitkällä painalluksella",
          "votes": "ääntä",
          "Show results to viewers": "Näytä kyselyn tulokset katsojille",
          "Hide results from viewers": "Piilota kyselyn tulokset katsojilta",
          "InfoModalHeader": "Tietoa palvelusta",
          "InfoModalP1": "Digitan Kysy katsojalta -palvelu mahdollistaa interaktiivisten kyselyiden luomisen ja aktivoinnin HbbTV -laitteisiin.",
          "InfoModalP2": "",
          "InfoModalP3": "",
          "Enter poll question": "Kysymys",
          "Enter choice": "Vastausvaihtoehto",
          "Choose a channel": "Valitse kanava jossa kysely näytetään:",
          "No channels": "Sinulla ei ole käytössä olevia kanavia",
          "second": "sekuntia",
          "second_plural": "sekunti",
          "minute": "minuutti",
          "minute_plural": "minuuttia",
          "hour": "tunti",
          "hour_plural": "tuntia",
          "Show poll results to TV viewers": "Näytä kyselyn tulokset katsojille",
          "Don't show poll results to TV viewers": "Älä näytä kyselyn tuloksia katsojille",
          "Save new poll": "Tallenna kysely",
          "Long press to create the poll": "Luo kysely pitkällä painalluksella",
          "Not a valid poll": "Syötä otsikko, vähintään kaksi ensimmäistä vastausta ja valitse kanava",
          "Cancel": "Peruuta",
          "Channel where the poll is shown in": "Millä kanavalla kysely näytetään",
          "How long the poll is visible": "Kuinka kauan kyselyä näytetään",
          "Back to all polls": "Takaisin kaikkiin kyselyihin",
          "Edit this poll": "Muokkaa kyselyä",
          "Poll closed": "Kysely suljettu",
          "Create a new poll": "Luo uusi kysely",
          "Duplicate this poll": "Kopioi tämä kysely",
          "Something went wrong": "Jokin meni pieleen",
          "Try again later": "Yritä myöhemmin uudestaan tai ota yhteys ylläpitoon",
        }
      }
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
