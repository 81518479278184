// ---------------
// List polls using PollBox components
// ---------------
import React, { Component } from 'react'
import { BrowserRouter as Redirect, Link } from "react-router-dom" // Route user to right view
import posed, {PoseGroup} from 'react-pose' // animate dom
import styled, { withTheme } from 'styled-components'
import { channelThemes } from './ChannelThemes'
import PopUp from './PopUp'

// Themes are the account specific themes for the UI
import { themes } from './themes' // Visual theme configurations
import { layout } from './layoutConfig' // Layout configurations

// Helpers
import axios from 'axios' // Promised based - Make XMLHttpRequests from the browser

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import moment from 'moment' // Times and dates

import PollBox from './PollBox'  // Display the polls in a box style
import Spinner from './Spinner'  // Display the polls in a box style
import AppHeader from './AppHeader'  // Header contains the logo, settings, logout link and stuff like that

// Logging
import Log from './Log' // This helps us not firing log events in production

const AnimatedPollContents = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delayChildren: 0,
    staggerChildren: 50,
  },
  exit: { y: 0, opacity: 0 }
})
const AnimatedSpinner = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})
const AnimatedHeader = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: -64, opacity: 0 },
  initialPose: 'exit'
})
const AnimatedPollBox = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 32, opacity: 0 },
})

const PollBoxContentWrapper = styled(AnimatedPollBox)`
  width: 100%;
  @media (min-width: ${layout.breakpoint.sm}) {
     max-width: 50%;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    max-width: 33%;
  }
`

const PollListWrapper = styled(AnimatedPollContents)`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  max-width: 1680px;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (min-width: ${layout.breakpoint.xxl}) {
    padding: 1.5rem
  }
`

const BoxLink = styled(Link)`
  text-decoration: none;
  width: 50%;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 33%;
  }
`


class PollList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
      polls: '',
      authFailed: false,
      infoModalOpen: false
    }
  }

  componentDidMount() {
    //ReactGA.initialize('UA-88071868-2') // Initialize google analytics
    //this.sendGAPageview()
    this.getNetworkInformation()
    this.props.setTheme()
  }

  getPollsData() {
    //axios.get(`polls.json`)
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/poll/`
      })
     .then(res => {
        const data = res.data
        this.setState({
          dataLoaded: true,
          polls: data.polls,
        })
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.props.refreshToken()
          } else {
            Log.error('Response error', 'PollList (getPollsData)')
            this.openInfoModal()
          }
        } else if (error.request) {
          Log.error('Request error', 'PollList (getPollsData)')
          Log.error(error.request)
          this.openInfoModal()
        } else {
          Log.error('Other error', 'PollList (getPollsData)')
          Log.error(error.message)
          this.openInfoModal()
        }
      })
    } else {
      Log.error('No token found, opening log in view', 'PollList (getPollsData)')
      this.openInfoModal()
    }
  }

  getNetworkInformation() {
    // Get JWT token from localStorage which is created on login
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/channels/`
      })
        .then(res => {
          // We get the channel names from data that belong to this username's network
          const data = res.data
          this.setState({ networkChannels: data.channels })
          if (data.is_admin === true) {
            this.setState({ showThemeOptions: true })
          }
          this.getPollsData()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else {
              Log.error('Response error')
              this.openInfoModal()
            }
          } else if (error.request) {
            Log.error('Request error', 'PollList (getNetworkInformation)')
            Log.error(error.request)
            this.openInfoModal()
          } else {
            Log.error('Other error', 'PollList (getNetworkInformation)')
            Log.error(error.message)
            this.openInfoModal()
          }
        })
    } else {
      Log.error('No token found, opening log in view')
      this.openInfoModal()
    }
  }

  handleLogout = (event) => {
    Log.info('Logging out user - clearing local storage and resetting state', 'PollList (handleLogout)')
    event.preventDefault()
    window.localStorage.clear()
    this.props.history.push('/login')
    /*ReactGA.event({
      category: 'User',
      action: 'Logged out'
    })*/
  }

  openInfoModal = () => {
    this.setState({ infoModalOpen: true })
  }

  closeInfoModal = () => {
    this.setState({ infoModalOpen: false }, this.props.history.push('/login'))
  }

  render() {
    const { t, i18n } = this.props
    const { polls, dataLoaded, authFailed } = this.state
    const now = Date.now()

    moment.locale(i18n.language) // Set moment localisation to current language
    let content

    if (dataLoaded) {
      content = (
        <PollListWrapper key="pollContents">
          {polls.map(poll => (
            <PollBoxContentWrapper key={poll.id}>
              <BoxLink to={`/poll/${poll.id}`} key={poll.id}>
                <PollBox
                  id={poll.id}
                  channelName={poll.channel.replace(/ /g,'').replace(/&/,'').replace(/\(|\)/g,'').replace(/\./g, '').toLowerCase()}
                  title={poll.title}
                  backgroundImage={ channelThemes.logos[poll.owner] }
                  pollQuestions={poll.items}
                  activationEnd={poll.activation_end}
                  pollStatus={ moment(poll.activation_end).isAfter(now) ? 'active' : moment(poll.activation_end).isBefore(now) ? 'closed' : 'activatable' }
                />
              </BoxLink>
            </PollBoxContentWrapper>
          ))}
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </PollListWrapper>
      )
    }

    else {
      content = (
        <AnimatedSpinner key="spinner">
          <Spinner loaderStatusMessage={t('Getting data')} />
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </AnimatedSpinner>
      )
    }

    if (authFailed === true) {
      return <Redirect to='/login' />
    }

    return (
      <PoseGroup>
        <AnimatedHeader key="header">
          <AppHeader
            userName={this.state.username}
            handleLogout={this.handleLogout}
            handleThemeChange={this.props.handleThemeChange}
            showThemeOptions={this.state.showThemeOptions}
            themeList={Object.keys(themes)}
            themesString={t('Themes')}
            logoutString={t('Log out')}
          />
        </AnimatedHeader>
        {content}
      </PoseGroup>
    )
  }
}

export default withTranslation('translations')(withTheme(PollList))
