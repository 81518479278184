// ---------------
// View when editing, creating or duplicating poll
// ---------------
import React, { Component } from 'react'
import { Redirect, Link } from "react-router-dom" // Route user to right view
import styled, { keyframes, withTheme } from 'styled-components'
import { lighten, darken, rgba } from 'polished' // Style effects
import { channelThemes } from './ChannelThemes' // Get channel logos and colors
import posed, {PoseGroup} from 'react-pose' // UI animations
import PopUp from './PopUp'

// Themes are the account specific themes for the UI
import { layout } from './layoutConfig' // Layout configurations

// Helpers
import axios from 'axios' // Promised based - Make XMLHttpRequests from the browser

// Logging
import Log from './Log' // This helps us not firing log events in production

// Localization & time
import { withTranslation } from 'react-i18next' // Translate strings
import moment from 'moment' // Times and dates

// Components
import Spinner from './Spinner' // Wait spinner


const AnimatedPollContents = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 24, opacity: 0 }
})

const AnimatedSpinner = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})

const PollBoxContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 2rem;
  border-radius: 12px;
  flex-direction: column;
  transition: border .2s ease;
  text-align:left;
  position: relative;
  height: auto;
  background-image: linear-gradient(
    ${props => lighten(0.08, props.themeBg)},
    ${props => darken(0.06, props.themeBg)}
  );
  max-width: 980px;
  margin: 0 auto;
  font-size: 1rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    flex-direction: row;
    padding: 4rem;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.1rem;
    padding: 4rem;
  }
  &:active {
    text-decoration: none;
  }
`
const PollBoxContent = styled.div`
  padding: 0;
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 0 2rem 0 0;
    min-width: 480px;
  }
`
const PollBoxControlsBottom = styled.div`
  align-self: flex-end;
  width: 100%;
  padding-top: 1em;
  border-top: 1px solid rgba(255,255,255,.1)
`
const PollBoxControlsTop = styled.div`
  width: 100%;
`
const PollBoxControls = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  border-top: 1px solid  ${props => rgba(props.theme.textColor, 0.2)};
  padding: 2rem 0 0 0;
  margin-top: 2rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    border-left: 1px solid  ${props => rgba(props.theme.textColor, 0.2)};
    border-top: 0;
    padding: 0 0 0 2rem;
    width: 40%;
    margin-top: 0rem;
    min-width: 240px;
  }
`
const fillButton = keyframes`
  to {
    width: 100%;
    opacity: 1;
  }
`
const ActivationButtonHolder = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 16, opacity: 0 }
})
const ActivationButton = styled.button`
  font-size: 1.1em;
  user-select: none;
  background: none;
  width: 100%;
  border: 0;
  border-radius: 6px;
  border: 3px solid ${props => props.theme.accentColor};
  padding: 1rem 1rem 1.1rem;
  color: ${props => props.theme.accentColor};
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(0,0,0,.4);
  box-sizing: border-box;
  position: relative;
  transition: color .3s, border-color .3s, opacity .3s;
  &:hover {
    color: ${props => lighten(0.3, props.theme.accentColor)}
    border-color: ${props => lighten(0.3, props.theme.accentColor)}
  }
  &:disabled {
    opacity: .5;
  }
  &:focus {
    outline: 0;
  }
  .buttonFillAnimation {
    width: 0%;
    height: 100%;
    display: block;
    opacity: 0.2;
    bottom: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    transition: opacity .3s;
    background: ${props => rgba(props.theme.accentColor, 0.9)};
    background-blend-mode: multiply;
  }
  &.animateFill .buttonFillAnimation {
    animation: ${fillButton} 2s forwards;
  }
`
const HelperText = styled.div`
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
  text-align: center;
  padding: 0.8em 0;
`

const PollBoxTitle = styled.input`
  display: inline;
  font-size: 1.6em;
  font-weight: 200;
  width: 100%;
  padding: .7em;
  border: 0;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 1em;
  text-decoration: none;
  @media (min-width: ${layout.breakpoint.sm}) {
  }
  @media (min-width: ${layout.breakpoint.lg}) {
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`

const PollBoxQuestions = styled.div`
  position: relative;
`
const Wrapper = styled(AnimatedPollContents)`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  justify-content: center;
      align-items: center;
  min-height: 100vh;
`
const SelectBoxGroup = styled.div`

`
const SelectBox = styled.select`
  width: 100%;
  display: block;
  margin: 0.5rem 0;
  -webkit-appearance: none;
  padding: 0.5rem;
  border: 0;
`
const RadioButtonGroup = styled.div`
  display: block;
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: ${props => rgba(props.borderColor, 0.1)};
`
const RadioButton = styled.label`;
  display: flex;
  box-sizing: border-box;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  line-height: 1.2rem;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  input {
    margin-right: 0.8rem;
    display: none;
  }
  input + div {
    transition: background-color .3s, border-color .3s, opacity .3s;
    padding: 0.5rem;
    border-radius: 6px;
    border: 2px solid transparent;
    opacity: 0.8;
    width: 100%;
    background-color: ${props => rgba(props.borderColor, 0.1)};
  }
  input:checked + div {
    opacity: 1;
    border: 2px solid ${props => rgba(props.theme.accentColor, 1)};
    background-color: ${props => rgba(props.theme.accentColor, 0.1)};
  }
`
const PollBoxQuestion = styled.input`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  padding: .7em;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1.2em;
  @media (min-width: ${layout.breakpoint.sm}) {
     font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.1em;
  }
  &:nth-child(1) .bar {
    background-color: #E35050;
  }
  &:nth-child(2) .bar {
    background-color: #2AC77E;
  }
  &:nth-child(3) .bar {
    background-color: #FFE8A8;
  }
  &:nth-child(4) .bar {
    background-color: #6C92FF;
  }
`

const BackLink = styled(Link)`
  color:  ${props => rgba(props.theme.textColor, 0.8)};
  text-decoration: none;
  padding: 1rem 1.5rem 1.05rem;
  margin: 1rem;
  border-radius: 6px;
  background: ${props => rgba(props.theme.textColor, 0.2)}
`

const titleMaxLength = 70
const questionMaxLength = 50

const prettifyTimeout = (timeoutValue, t) => {
  if(timeoutValue < 60) {
    return `${timeoutValue} ${t('second', {count: timeoutValue})}`
  } else if (timeoutValue < 3600) {
    return `${timeoutValue / 60} ${t('minute', {count: timeoutValue/60})}`
  } else {
    return `${timeoutValue / 3600} ${t('hour', {count: timeoutValue/3600})}`
  }
}

class EditPoll extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
      authFailed: false,
      longpressActive: false,
      pollValidated: false,
      pollTitle: '',
      pollQuestion1: '',
      pollQuestion2: '',
      pollQuestion3: '',
      pollQuestion4: '',
      pollChannel: '',
      pollTimeout: 300,
      pollPublishType: 'show-results',
      infoModalOpen: false
    }
  }

  componentDidMount() {
    //ReactGA.initialize('UA-88071868-2') // Initialize google analytics
    //this.sendGAPageview()
    this.getNetworkInformation()
  }

  createNewPoll(payload) {
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/poll/`
      })
      .then(res => {
        Log.info('Sending poll data')
        axios({
          method:'post',
          headers: { Authorization: 'Bearer ' + token },
          data: payload,
          url:`${window.baseURL}/poll/`
        })
        .then(res => {
          Log.info('Poll data sent')
          const poll = res.data
          this.props.history.push(`/poll/${poll.id}/`)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else if (error.request) {
              Log.error('Request error')
              Log.error(error.request)
              this.openInfoModal()
            } else {
              Log.error('Other error')
              Log.error(error.message)
              this.openInfoModal()
            }
          }
        })
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.props.refreshToken()
          } else {
            Log.error(error.response)
            this.openInfoModal()
          }
        }
      })
    }
  }


  updatePoll(payload) {
    const { id } = this.props.match.params
    const token = this.props.token
    Log.info('Updating poll data')
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/poll/`
      })
      .then(res => {
        Log.info('Sending poll data')
        axios({
          method:'put',
          headers: { Authorization: 'Bearer ' + token },
          data: payload,
          url:`${window.baseURL}/poll/${id}/`
        })
        .then(res => {
          Log.info('Poll data sent', 'EditPoll (updatePoll)')
          this.props.history.push(`/poll/${id}/`)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else if (error.request) {
              Log.error('Request error', 'EditPoll (updatePoll)')
              Log.error(error.request)
              this.openInfoModal()
            } else {
              Log.error('Other error', 'EditPoll (updatePoll)')
              Log.error(error.message)
              this.openInfoModal()
            }
          }
        })
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.props.refreshToken()
          } else {
            Log.error(error.response)
            return false
          }
        }
      })
    }
    else this.openInfoModal()
  }

  getPollData() {
    const { id } = this.props.match.params
    const token = this.props.token
    if (id === undefined) {
      // If id is not available, throw user back to frontpage where we list all polls
      this.props.history.push('/')
    } else {
      if (token) {
        axios({
          method:'get',
          headers: { Authorization: 'Bearer ' + token },
          url:`${window.baseURL}/poll/${id}/`
        })
        .then(res => {
            const poll = res.data
            const now = Date.now()
            const isDuplicate = this.props.isDuplicate
            const channelName = this.normalizeChannelname(poll.channel)
            const pollStatus = moment(poll.activation_end).isAfter(now) ? 'active' : moment(poll.activation_end).isBefore(now) ? 'closed' : 'activatable'
            if (pollStatus === 'activatable' || isDuplicate) {

              const questions = poll.items
              let pollQuestions = []
              for(let i = 0; i < questions.length; i++) {
                const question = questions.filter(x => x.pos === i).map(x => x.value)
                pollQuestions.push(question)
              }

              this.props.setTheme(channelName)

              this.setState({
                poll: poll,
                authFailed: false,
                pollStatus: pollStatus,
                pollTitle: poll.title,
                pollQuestion1: pollQuestions[0],
                pollQuestion2: pollQuestions[1],
                pollQuestion3: pollQuestions[2] ? pollQuestions[2] : '',
                pollQuestion4: pollQuestions[3] ? pollQuestions[3] : '',
                pollChannel: poll.channel,
                pollTimeout: poll.timeout,
                pollPublishType: poll.publish_type,
                dataLoaded: true,
              })
            } else {
              this.props.history.push(`/poll/${id}/`)
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.props.refreshToken()
              } else if (error.request) {
                Log.error('Request error', 'EditPoll (getPollData)')
                Log.error(error.request)
                this.openInfoModal()
              } else {
                Log.error('Other error', 'EditPoll (getPollData)')
                Log.error(error.message)
                this.openInfoModal()
              }
            }
          })
        } else {
          Log.error('No token found, opening log in view', 'EditPoll (getPollData)')
          this.openInfoModal()
        }
      }
  }

  getNetworkInformation() {
    // Get JWT token from localStorage which is created on login
    const { id } = this.props.match.params
    const token = this.props.token
    if (token) {
      axios({
        method:'get',
        headers: { Authorization: 'Bearer ' + token },
        url:`${window.baseURL}/channels/`
      })
        .then(res => {
          // We get the channel names from data that belong to this username's network
          const data = res.data
          console.log(data)
          if (id) {
            this.setState({networkChannels: data.channels})
            this.getPollData()
          } else {
            this.setState({
              networkChannels: data.channels,
              dataLoaded: true,
            },
            !data.channels.length
            ? undefined
            : this.handleChannel(data.channels[0]))
          }
          if (data.is_admin === true) {
            this.setState({ showThemeOptions: true })
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.props.refreshToken()
            } else {
              Log.error('Response error', 'EditPoll (getNetworkInformation)')
              this.openInfoModal()
            }
          } else if (error.request) {
            Log.error('Request error', 'EditPoll (getNetworkInformation)')
            Log.error(error.request)
            this.openInfoModal()
          } else {
            Log.error('Other error', 'EditPoll (getNetworkInformation)')
            Log.error(error.message)
            this.openInfoModal()
          }
        })
    } else {
      Log.error('No token found, opening log in view', 'EditPoll (getNetworkInformation)')
      this.openInfoModal()
    }
  }

  getChannelLogo = (channelId) => {
    if (channelThemes.logos[channelId]) {
      return channelThemes.logos[channelId]
    } else {
      return ''
    }
  }

  savePoll = () => {
    const { id } = this.props.match.params
    const isDuplicate = this.props.isDuplicate

    this.setState({
      longpressActive: false,
    })

    let { pollTitle, pollQuestion1, pollQuestion2, pollQuestion3, pollQuestion4 } = this.state
    pollTitle = pollTitle.trim()
    pollQuestion1 = pollQuestion1.toString().trim()
    pollQuestion2 = pollQuestion2.toString().trim()
    pollQuestion3 = pollQuestion3.toString().trim()
    pollQuestion4 = pollQuestion4.toString().trim()

    const questions = [
      {
        "pos": 0,
        "value": pollQuestion1
      },
      {
        "pos": 1,
        "value": pollQuestion2
      },
      {
        "pos": 2,
        "value": pollQuestion3
      },
      {
        "pos": 3,
        "value": pollQuestion4
      }
    ]

    const filledQuestions =  questions.filter(function(question) {
      return question.value !== ''
    })

    const payload = {
      "title": pollTitle,
      "timeout": this.state.pollTimeout,
      "channel": this.state.pollChannel,
      "publish_type": this.state.pollPublishType,
      "items": filledQuestions
    }
    console.log(payload)
    console.log('is duplicate?' + isDuplicate)
    if (id && !isDuplicate) {
      this.updatePoll(payload)
    } else {
      this.createNewPoll(payload)
    }
  }

  validateForm() {
    let { pollTitle, pollQuestion1, pollQuestion2, pollQuestion3, pollQuestion4, pollChannel  } = this.state
    pollTitle = pollTitle.trim()
    const trimmedPollQuestion1 = pollQuestion1.toString().trim()
    const trimmedPollQuestion2 = pollQuestion2.toString().trim()
    const trimmedPollQuestion3 = pollQuestion3.toString().trim()
    const trimmedPollQuestion4 = pollQuestion4.toString().trim()

    if ( pollTitle && trimmedPollQuestion1 && trimmedPollQuestion2 && pollChannel ) {
      if (trimmedPollQuestion3 && trimmedPollQuestion4) {
        this.setState({pollValidated: true})
      }
      else if (trimmedPollQuestion3 && !trimmedPollQuestion4) {
        this.setState({pollValidated: true})
      }
      else if (!trimmedPollQuestion3 && trimmedPollQuestion4) {
        this.setState({pollValidated: false})
      }
      else {
        this.setState({pollValidated: true})
      }
    } else {
      this.setState({pollValidated: false})
    }
  }
  handleTitle = (event) => {
    this.setState({pollTitle: event.target.value},() => this.validateForm())
  }
  handleQuestion1 = (event) => {
    this.setState({pollQuestion1: event.target.value},() => this.validateForm())
  }
  handleQuestion2 = (event) => {
    this.setState({pollQuestion2: event.target.value},() => this.validateForm())
  }
  handleQuestion3 = (event) => {
    this.setState({pollQuestion3: event.target.value},() => this.validateForm())
  }
  handleQuestion4 = (event) => {
    this.setState({pollQuestion4: event.target.value},() => this.validateForm())
  }
  handleChannel = (channelName: string) => {
    const normalizedName = this.normalizeChannelname(channelName)
    this.setState({pollChannel: channelName},() => this.validateForm())
    this.props.setTheme(normalizedName)
  }
  handleTimeout = (timeoutValue: string) => {
    this.setState({pollTimeout: timeoutValue})
  }
  handlePublishType = (publishType: string) => {
    this.setState({pollPublishType: publishType})
  }

  normalizeChannelname = (channelName) => {
    return channelName.replace(/ /g,'').replace(/&/,'').replace(/\(|\)/g,'').replace(/\./g, '').toLowerCase()
  }

  handleButtonPress = (event) => {
    var buttons = event.buttons;
    if (buttons === 2) {
      // Right click, do nothing
    } else {
      this.setState({ longpressActive: true })
      this.buttonPressTimer = setTimeout(() => this.savePoll(), 2000)
    }
  }

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer)
    this.setState({
      longpressActive: false
    })
  }

  openInfoModal = () => {
    this.setState({ infoModalOpen: true })
  }

  closeInfoModal = () => {
    this.setState({ infoModalOpen: false, authFailed: true })
  }

  render() {
    const { t } = this.props
    const { id } = this.props.match.params
    const { pollValidated, dataLoaded, authFailed, networkChannels } = this.state
    const timeoutOptions = [30, 60, 120, 300, 600, 1800, 3600, 21600, 43200]
    let content, backLink

    if (id) {
      backLink = '/poll/' + id
    } else {
      backLink = '/'
    }

    if (dataLoaded) {

      content = (
        <Wrapper key="editPollWrapper">
          <PollBoxContainer  id={this.props.channelName} themeBg={this.props.theme.background}>
            <PollBoxContent>
              <PollBoxTitle
                placeholder={t('Enter poll question')}
                value={this.state.pollTitle}
                maxLength={titleMaxLength}
                onChange={this.handleTitle}
              />
              <PollBoxQuestions>
                <PollBoxQuestion
                  placeholder={t('Enter choice')}
                  value={this.state.pollQuestion1}
                  onChange={this.handleQuestion1}
                  maxLength={questionMaxLength}
                />
                <PollBoxQuestion
                  placeholder={t('Enter choice')}
                  value={this.state.pollQuestion2}
                  onChange={this.handleQuestion2}
                  maxLength={questionMaxLength}
                />
                <PollBoxQuestion
                  placeholder={t('Enter choice')}
                  value={this.state.pollQuestion3}
                  onChange={this.handleQuestion3}
                  maxLength={questionMaxLength}
                />
                <PollBoxQuestion
                  placeholder={t('Enter choice')}
                  value={this.state.pollQuestion4}
                  onChange={this.handleQuestion4}
                  maxLength={questionMaxLength}
                />
              </PollBoxQuestions>
            </PollBoxContent>

            <PollBoxControls>
              <PollBoxControlsTop>
                <SelectBoxGroup>
                  <label>{t('Channel where the poll is shown in')}:</label>
                  <SelectBox value={this.state.pollChannel} onChange={event => this.handleChannel(event.target.value)}>
                    {!networkChannels.length
                    ? <option key='empty' value=''>{t('No channels')}</option>
                    : networkChannels.map(channel => (
                      <option key={channel} value={channel}>
                        {channel}
                      </option>
                    ))}
                  </SelectBox>
                </SelectBoxGroup>
                <SelectBoxGroup>
                  <label>{t('How long the poll is visible')}:</label>
                  <SelectBox value={this.state.pollTimeout} onChange={event => this.handleTimeout(event.target.value)}>
                    {timeoutOptions.map(timeoutValue => (
                      <option key={timeoutValue} value={timeoutValue}>
                        {prettifyTimeout(timeoutValue, t)}
                      </option>
                    ))}
                  </SelectBox>
                </SelectBoxGroup>
                <RadioButtonGroup borderColor={this.props.theme.borderColor}>
                  <RadioButton borderColor={this.props.theme.borderColor}>
                    <input
                      type='radio'
                      name='pollVisibility'
                      value='show-results'
                      checked={this.state.pollPublishType === 'show-results'}
                      onChange={event => this.handlePublishType(event.target.value)}
                    />
                    <div>{t(`Show poll results to TV viewers`)}</div>
                  </RadioButton>
                  <RadioButton borderColor={this.props.theme.borderColor}>
                    <input
                      type="radio"
                      name="pollVisibility"
                      value='no-results'
                      checked={this.state.pollPublishType === 'no-results'}
                      onChange={event => this.handlePublishType(event.target.value)}
                    />
                    <div>{t(`Don't show poll results to TV viewers`)}</div>
                  </RadioButton>
                </RadioButtonGroup>
              </PollBoxControlsTop>
              <PollBoxControlsBottom>
                <PoseGroup>
                    <ActivationButtonHolder key='activationButton' >
                      <ActivationButton
                        onTouchStart={this.handleButtonPress}
                        onTouchEnd={this.handleButtonRelease}
                        onMouseDown={this.handleButtonPress}
                        onMouseUp={this.handleButtonRelease}
                        onMouseLeave={this.handleButtonRelease}
                        disabled={!pollValidated}
                        className={
                          this.state.longpressActive ? 'animateFill' : ''
                        }>
                          {t('Save new poll')}
                          <div className="buttonFillAnimation" />
                      </ActivationButton>
                      {pollValidated ?
                        <HelperText>{t('Long press to create the poll')}</HelperText>
                      : <HelperText>{t('Not a valid poll')}</HelperText>}
                    </ActivationButtonHolder>
                </PoseGroup>
              </PollBoxControlsBottom>
            </PollBoxControls>
          </PollBoxContainer>
          <BackLink to={backLink}>{t('Cancel')}</BackLink>
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </Wrapper>
      )
    }

    // If we've not yet gotten the data loaded (due to slow network for example)
    else {
      content = (
        <AnimatedSpinner key="spinner">
          <Spinner loaderStatusMessage={t('Getting data')} />
          <PopUp title={t('Something went wrong')} p1={t('Try again later')} infoModalOpen={this.state.infoModalOpen} closeInfoModal={this.closeInfoModal} />
        </AnimatedSpinner>
      )
    }

    // If authentication fails when fetching data, throw user back to login
    if (authFailed === true) {
      return <Redirect to='/login' />
    }

    // Render the content
    return (
      <PoseGroup>
        {content}
      </PoseGroup>
    )
  }
}

export default withTranslation('translations')(withTheme(EditPoll))
